#deliveryOptions {
	.help-bubble {
		margin-left: 5px;
		top: 1px;
	}

	.notification-sound-container {
		.container-heading {
			margin-right: 3px;
		}

		.slds-visual-picker_vertical {
			width: 95%;

			.slds-visual-picker__figure {
				height: auto;
				width: auto;
			}
		}
	}

	.interactive-notification-container {
		margin-top: 30px;

		.slds-combobox_container {
			width: 400px;
		}

		.notification-buttons-container {
			margin-top: 10px;

			.container-heading {
				margin-bottom: 0px;
			}
		}
	}

	.ios-badge-toggle-container {
		margin-top: 30px;

		.slds-checkbox--off,
		.slds-checkbox--on {
			text-align: center;
		}
	}

	.send-window-container {
		margin-top: 30px;

		.slds-checkbox--off,
		.slds-checkbox--on {
			text-align: center;
		}

		.window-picker {
			#startTime {
				width: 129px;
				margin-right: 10px;
			}

			#endTime {
				width: 129px;
			}

			.slds-input {
				/* Override slds-visual-picker's input styling */
				width: 100%;
				position: relative;
				height: initial;
				border: 1px solid #dddbda;
				clip: initial;
				overflow: initial;
				margin: 0;
				padding: 0 1rem 0 0.75rem;
			}
		}

		.timezone-picker {
			.slds-form-element {
				display: inline-block;
				width: 90%;
			}
		}
	}

	.slds-visual-picker {
		.slds-input {
			position: relative;
			width: 100%;
			border: 1px solid #dddbda;

			&:active,
			&:focus {
				border-color: #0070d2;
				box-shadow: 0 0 3px #0070d2;
			}
		}

		.slds-listbox {
			.slds-media {
				display: flex;
			}
		}
	}

	.campaign-container {
		margin-top: 30px;

		.selected-campaign {
			.slds-pill__label {
				margin-left: 0.25rem;
				font-size: 12px;
			}

			.btn-change-campaign {
				margin-left: 10px;
			}
		}
	}
}

#campaign-selector {
	height: 100%;
	margin-top: 20px;

	.slds-card {
		height: calc(100% - 52px);
		box-shadow: none;
		border-radius: 0px;

		.slds-card__body {
			height: calc(100% - 56px) !important;
		}
	}
}
