.message-configuration {
	.slds-combobox_container {
		width: 300px;
	}

	.slds-icon_container {
		&.slds-icon-utility-check {
			color: #0070d2;
		}
	}

	.disabled-text {
		color: #999999;
	}
}

[data-view="messageConfig"],
#messageConfiguration {
	.expired {
		color: #c23934;
		text-transform: uppercase;
	}
}
